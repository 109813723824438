<template>
  <div
    v-once
    class="wistia_responsive_padding"
    style="padding: 56.25% 0 0 0; position: relative"
  >
    <div
      class="wistia_responsive_wrapper"
      style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
    >
      <div
        class="wistia_embed seo=true videoFoam=true"
        :class="`wistia_async_${videoId}`"
        style="height: 100%; position: relative; width: 100%"
      >
        <div
          class="wistia_swatch"
          style="
            height: 100%;
            left: 0;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            transition: opacity 200ms;
            width: 100%;
          "
        >
          <img
            :src="`https://fast.wistia.com/embed/medias/${videoId}/swatch`"
            style="
              filter: blur(5px);
              height: 100%;
              object-fit: contain;
              width: 100%;
            "
            width="1280"
            height="720"
            alt=""
            aria-hidden="true"
            onload="this.parentNode.style.opacity=1;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  videoId: {
    type: String,
    required: true
  }
});

function loadScript() {
  const videoScript = document.createElement('script');
  videoScript.src = `https://fast.wistia.com/embed/medias/${props.videoId}.jsonp`;
  document.body.appendChild(videoScript);
  const script = document.createElement('script');
  script.src = 'https://fast.wistia.com/assets/external/E-v1.js';
  document.body.appendChild(script);
}

onMounted(() => {
  loadScript();
});
</script>
